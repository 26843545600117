// extracted by mini-css-extract-plugin
export var col = "RoadmapSection-module--col--LFSCs";
export var container = "RoadmapSection-module--container--hWdth";
export var content = "RoadmapSection-module--content--CknGX";
export var disclaimer = "RoadmapSection-module--disclaimer--z9Vfj";
export var image = "RoadmapSection-module--image--Aoicm";
export var liIcon = "RoadmapSection-module--li-icon--5Mv4Z";
export var milestone = "RoadmapSection-module--milestone--Y+LXb";
export var milestoneImg = "RoadmapSection-module--milestone-img--mfYjH";
export var milestoneInfo = "RoadmapSection-module--milestone-info--2r3C7";
export var q1 = "RoadmapSection-module--q1--PBA3L";
export var q1a = "RoadmapSection-module--q1a--CpgIq";
export var q2 = "RoadmapSection-module--q2--yt5ev";
export var q3 = "RoadmapSection-module--q3---6TZD";
export var roadmap = "RoadmapSection-module--roadmap--wsioX";
export var roadmapVert = "RoadmapSection-module--roadmap-vert--tK5jv";
export var row = "RoadmapSection-module--row--NS3x1";